import moment from 'moment';
import { config } from 'smg-common';

import {
    formatters,
    getStartAndEndDatesOfActivitiesAndItems,
    getTotalMediaInvestmentOfSelfServeActivities,
    simpleDate,
} from '../../index.js';

const bmgFirstPartyDataTemplate = {
    name: 'BMG 1PD template',
    isSelfServe: true,
    pandaDocId: 'E5HFCn9LtNYFZCgSFq7h5F',
    companies: ['61dc2bee7bd8da6e6e19c312'],
    recipientRoles: [
        { label: 'Client\'s email', value: 'Client' },
    ],
    customFields: [
        { label: 'PO amount required', value: 'poAmount' },
        {
            label: 'Credit line to be used',
            value: 'creditLine',
            type: 'select',
            // These values are sentence case rather than as slugs as they are displayed on the booking form.
            // They are not stored in the database.
            options: [{
                label: 'SMG credit line',
                value: 'SMG',
            }, {
                label: 'Agency credit line',
                value: 'Agency',
            }, {
                label: 'Brand credit line',
                value: 'Brand',
            }],
        },
    ],

    getDocumentName: (campaign, plan, activities, customFields) => {
        const now = moment().format('YYMMDD-HHmm');
        const { startDate, endDate } = getStartAndEndDatesOfActivitiesAndItems(activities, []);
        const liveMonthIndex = simpleDate.getMonth(startDate)
        const liveMonth = simpleDate.months().find((m) => m.offset === liveMonthIndex);

        return formatters.asFilename(`[${now}] AMP BM - ${campaign.company.name} - ${campaign.name} - ${liveMonth.label}`);
    },

    getTokens: (campaign, plan, activities) => {
        const totalCost = getTotalMediaInvestmentOfSelfServeActivities(activities);
        const fskus = campaign.skus?.map((sku) => sku.name).join(', ') || '-';

        return [
            { name: 'brand.name', value: campaign.brands[0].name },
            { name: 'campaign.name', value: campaign.name },
            { name: 'campaign.totalCost', value: formatters.asCurrency(totalCost, { decimals: 2}) },
            { name: 'category.name', value: plan.rCategory?.name || '-' },
            { name: 'company.name', value: campaign.company.name },
            { name: 'fskus', value: fskus },
            { name: 'plan.name', value: plan.name },
            { name: 'retailer.name', value: plan.retailer.name },
            { name: 'subcategory.name', value: plan.rSubCategory?.name || '-' },
        ];
    },

    getPricingTables: (plan, activities) => {
        let rows = [];
        const options = {
            optional: false,
            optional_selected: true,
            qty_editable: false,
        };

        const audienceTypeOptions = config.audiences.typeOptions;

        activities.forEach((activity) => {
            activity.audienceSegments?.forEach((audienceSegment, index) => {

                /**
                 * Price is a required field for PandaDocs pricing tables,
                 * even though we don't display the individual activity price on this template.
                 * As we are looping through audience segments in activities,
                 * we only add the activity price to the first index to avoid duplication feeding into the total price.
                 */
                const price = index === 0
                    ? (activity.estMetrics?.fees?.totalMediaInvestment || 0)
                    : 0;

                const audienceType = audienceTypeOptions.find((option) => option.value === audienceSegment.type);

                rows.push({
                    options,
                    data: {
                        QTY: 1,
                        'Name': activity.touchpoint?.name,
                        'Description': activity.description,
                        'StartDate': formatters.asDate(activity.startDate),
                        'EndDate': formatters.asDate(activity.endDate),
                        'AudienceName': audienceSegment.label,
                        'AudienceType': audienceType.label,
                        'Price': price,
                }});
            });

            if (!activity.audienceSegments?.length > 0) {
                const price = activity.estMetrics?.fees?.totalMediaInvestment || 0;

                rows.push({
                    options,
                    data: {
                        QTY: 1,
                        'Name': activity.touchpoint?.name,
                        'Description': activity.description,
                        'StartDate': formatters.asDate(activity.startDate),
                        'EndDate': formatters.asDate(activity.endDate),
                        'AudienceName': 'n/a',
                        'AudienceType': 'n/a',
                        'Price': price,
                }});
            }
        });

        return [{
            name: 'Pricing Table 1',
            data_merge: true,
            options: {
                currency: 'GBP',
            },
            sections: [
                {
                    title: 'Audience Section',
                    default: true,
                    rows,
                },
            ],
        }];
    },
};

export default bmgFirstPartyDataTemplate;

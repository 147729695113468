import { forEach, isArray, isEmpty, isFunction } from 'lodash';
import { useRef, useState } from 'react';

import AsFormik from './AsFormik';
import { Button, FileInput, Grid, Http, Icon, dialog, useFetch, toast } from '../';

import css from './Dropzone.module.scss';

const acceptTypes = {
    csv: { mimetype: 'text/csv', label: '.csv' },
    ppt: { mimetype: 'application/vnd.ms-powerpoint', label: '.ppt'},
    pptx: { mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', label: '.pptx' },
    xls: { mimetype: 'application/vnd.ms-excel', label: '.xls' },
    xlsx: { mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', label: '.xslx' },
    anyImage: { mimetype: 'image/gif,image/jpeg,image/jpg,image/png', label: '.gif, .jpeg, .jpg or .png' },
    anyPowerpoint: { mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint', label: '.ppt or .pptx' },
    anyExcel: { mimetype: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', label: '.xls or .xlsx' },
    anyExcelOrCsv: { mimetype: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv', label: '.xls, .xlsx or .csv' },
};

const mimetypes = {
    archive: new RegExp('^application/((x-b)?zip2?|x-7z-compressed|vnd.rar|x-tar)'),
    audio: new RegExp('^audio/.{2,}'),
    excel: new RegExp('^application/(vnd.openxmlformats-officedocument.spreadsheetml.sheet|vnd.ms-excel)|text/csv'),
    image: new RegExp('^image/.{2,}'),
    powerpoint: new RegExp('^application/(vnd.openxmlformats-officedocument.presentationml.presentation|vnd.ms-powerpoint)'),
    video: new RegExp('^video/.{2,}'),
    word: new RegExp('^application/(vnd.openxmlformats-officedocument.wordprocessingml.document|msword)'),
};

function draggedItemsMatchAccept(items = [], accept) {
    const acceptedMasks = (acceptTypes[accept]?.mimetype || accept).split(',');

    return [...items].filter((item) => acceptedMasks.find((mask) => {
        return acceptTypes[mask] || new RegExp(mask).test(item.type);
    })).length === items.length;
}

function getThumbnail(file = {}, props) {
    const defaultIcon = (<Icon className={css['thumbnail-icon']} name="fileGeneric" />);
    const fileExtension = file.name?.split('.').pop();

    let thumbnail;
    forEach(mimetypes, (mimetype, filetype) => {
        const matchesMimeTypeArchive = mimetype.test(file.mimetype) && filetype === 'archive';
        const matchesMimeTypeAudio = mimetype.test(file.mimetype) && filetype === 'audio';
        const matchesMimeTypeExcel = mimetype.test(file.mimetype) && filetype === 'excel';
        const matchesMimeTypeImage = mimetype.test(file.mimetype) && filetype === 'image';
        const matchesMimeTypePowerpoint = mimetype.test(file.mimetype) && filetype === 'powerpoint';
        const matchesMimeTypeVideo = mimetype.test(file.mimetype) && filetype === 'video';
        const matchesMimeTypeWord = mimetype.test(file.mimetype) && filetype === 'word';

        if (matchesMimeTypeArchive || ['zip', 'tar', 'tgz', 'gz', 'rar'].includes(fileExtension)) {
            thumbnail = (<Icon className={css['thumbnail-icon']} name="fileArchive" />);
        } else if (matchesMimeTypeAudio || ['wav', 'mp3', 'ogg', 'flac', 'aiff', 'flac'].includes(fileExtension)) {
            thumbnail = (<Icon className={css['thumbnail-icon']} name="fileAudio" />);
        } else if (matchesMimeTypeExcel || ['xls', 'xlsx', 'xltx', 'xlsb', 'csv'].includes(fileExtension)) {
            thumbnail = (<Icon className={css['thumbnail-icon']} name="fileExcel" />);
        } else if (matchesMimeTypeImage || ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'svg', 'bmp'].includes(fileExtension)) {
            thumbnail = (<img className={`${css['thumbnail-image']} ${css.image}`} src={file.url || file.src} alt="" />);
        } else if (matchesMimeTypePowerpoint || ['ppt', 'pptx', 'pptm'].includes(fileExtension)) {
            thumbnail = (<Icon className={css['thumbnail-icon']} name="filePowerpoint" />);
        } else if (matchesMimeTypeVideo || ['mp4', 'mov', 'wmv', 'avi', 'flv', 'mkv', 'webp'].includes(fileExtension)) {
            thumbnail = (<Icon className={css['thumbnail-icon']} name="fileVideo" />);
        } else if (matchesMimeTypeWord || ['doc', 'docx', 'docm', 'dot', 'odt', 'rtf'].includes(fileExtension)) {
            thumbnail = (<Icon className={css['thumbnail-icon']} name="fileWord" />);
        }
    });

    return (
        <div className="stack-col-center-center-2 text-center p-1" {...props}>
            <div className={css['thumbnail-container']}>{thumbnail || defaultIcon}</div>
            <div className={css['thumbnail-filename']}>{file.name}</div>
        </div>
    );
}

const Thumbnail = (props) => {
    const { image } = props;
    const [imageWithUrlAsArray, isLoading, errorMessage] = useFetch(
        // The /files/populate-urls endpoint always returns an array but we use this in a map loop in the dropzone, hence the 'forced' array return
        () => image.url ? Promise.resolve([image]) : Http.post('/files/populate-urls', { images: [image] }),
        [],
    );

    return (<>
        {isLoading && (<small>loading...</small>)}
        {errorMessage && (<small>could not fetch image</small>)}
        {imageWithUrlAsArray?.[0] && getThumbnail(imageWithUrlAsArray[0], { key: image._id })}
    </>);
};

const Dropzone = (props) => {
    const dropzoneRef = useRef();
    const fileInputRef = useRef();
    const {
        accept,
        className = '',
        disabled,
        error,
        downloadUrl,
        fileRequirements,
        icon = 'arrow_downward',
        maxNumberOfFiles = 10,
        multi = false,
        onChange,
        onDelete,
        preview = true,
        templateFileUrl,
        value, // value is either a File object (when multi===false) or an array of File objects (when multi===true)
    } = props;

    const defaultLabel = 'Drop your file here';

    const composeValue = (val) => {
        if (isArray(val)) return val;
        else if (val) return [val];
        else return [];
    };

    const [errorMessage, setErrorMessage] = useState(null);
    const [highlight, setHighlight] = useState(false);
    const [isDropAreaVisible, setIsDropAreaVisible] = useState(false);
    const [showFileRequirements, setShowFileRequirements] = useState(false);
    const [isFileInvalid, setIsFileInvalid] = useState(false);

    const valueAsArray = composeValue(value);
    const label = multi ? props.label : (value?.name || props.label);

    const handleDownloadTemplate = () => {
        toast.info('Downloading template...');
        window.location.href = templateFileUrl;
    };

    const toggleFileRequirements = () => {
        setShowFileRequirements(!showFileRequirements);
    };

    const handleDropzoneClick = () => {
        fileInputRef.current.openFileSelectionDialog();
    };

    /**
     * The default ondragover behaviour is to 'Reset the current drag operation to "none"'.
     * We need to cancel the default behaviour for the ondrop to work properly.
     * For more info see here: https://html.spec.whatwg.org/multipage/dnd.html#event-dnd-dragover
     */
    const handleDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.effectAllowed = 'all';
        event.dataTransfer.dropEffect = 'move';
    };

    const renderErrorMessage = (numberOfFiles) => {
        if (numberOfFiles < 0) return false;

        let errorMsg = `${numberOfFiles > 1 ? 'These files contain at least one file that' : 'This file'} is of a type that cannot be accepted. `;
        errorMsg += `Please make sure that ${numberOfFiles > 1 ? 'all your files are' : 'the file is'} any of these types: ${acceptTypes[accept].label}`;
        setErrorMessage(errorMsg);
        setIsFileInvalid(true);
    };

    const handleDragEnter = (event) => {
        if (!accept || draggedItemsMatchAccept(event.dataTransfer.items, accept)) {
            setErrorMessage(null);
            setIsDropAreaVisible(true);
        } else {
            renderErrorMessage(event.dataTransfer.items.length);
        }
    };

    const handleDragLeave = (event) => {
        if (!dropzoneRef.current.contains(event.relatedTarget)) {
            setIsDropAreaVisible(false);
            setErrorMessage(null);
            setIsFileInvalid(false);
        }
    };

    const upload = async (fileList) => {
        if (disabled) return;

        if (!fileList[0]) {
            toast.warn('No file found. Maybe you dropped the contents of a zipped file?');
            return;
        }

        if (maxNumberOfFiles && fileList.length > maxNumberOfFiles) {
            toast.warn(`You cannot upload more than ${maxNumberOfFiles} files in one go`);
            return;
        }

        const fileArray = Array.from(fileList);

        // Large files can make the dropzone unresponsive for some time: show message for files larger than 10MB
        const hasLargeFiles = fileArray.some((file) => file.size > 10 * 1024 * 1024)

        if (hasLargeFiles) {
            toast.info('Uploading large file(s). This could take some time.')
        }

        const filePromises = fileArray.map(async (file) => {
            const fileMeta = {
                name: file.name,
                mimetype: file.type,
                size: file.size,
            };

            const planAppsFile = await Http.post('/s3-files', fileMeta);
            await Http.uploadtoS3(planAppsFile.uploadUrl, file);

            return planAppsFile;
        });

        const uploadedFiles = await Promise.all(filePromises);

        onChange(multi ? [...valueAsArray, ...uploadedFiles] : uploadedFiles[0]);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const { files = [] } = event.dataTransfer;

        if (!accept || draggedItemsMatchAccept(event.dataTransfer.items, accept)) {
            setErrorMessage(null);
            upload(files);
        } else {
            renderErrorMessage(event.dataTransfer.items.length);
        }
    };

    const handleFileChange = (event) => {
        const { files = [] } = event.currentTarget;
        if (!accept || draggedItemsMatchAccept(event.target.files, accept)) {
            setErrorMessage(null);
            upload(files);
        } else {
            renderErrorMessage(event.target.files.length);
        }
    };

    const handleFileDownload = (event, fileId) => {
        event.stopPropagation();
        const url = isFunction(downloadUrl) ? downloadUrl(fileId) : downloadUrl;
        window.location.href = process.env.REACT_APP_API_URL + url;
    };

    const handleFileDelete = (event, file) => {
        event.stopPropagation();
        dialog.confirm(`Are you sure you want to delete "${file.name}"?`, 'Delete')
            .then(() => {
                if (isFunction(onChange)) {
                    onChange(multi ? value.filter((existingFile) => existingFile._id !== file._id) : null);
                }
                if (isFunction(onDelete)) {
                    onDelete(file);
                }
            });
    };

    const classes = [css.dropzone, 'stack-col-5', 'p-4', className];
    if (highlight) classes.push(css.highlight);
    if (isDropAreaVisible) classes.push(css['drop-area']);
    if (isFileInvalid) classes.push(css['is-invalid']);
    if (error) classes.push(css['has-error']);

    return (<>
        <div className={`stack-${!fileRequirements ? 'end' : 'even'}-center mb-2`}>
            {fileRequirements &&
                    <span className="text-small like-link" onClick={toggleFileRequirements} >
                        { showFileRequirements ? 'Hide' : 'View' } file requirements
                        <Icon className="text-small like-link" name={ showFileRequirements ? 'expand_more' : 'chevron_right' }/>
                    </span>
            }
            {templateFileUrl &&
                    <Button onClick={handleDownloadTemplate}>Download template</Button>
            }
        </div>
        {showFileRequirements && fileRequirements && (
            <div className="box-flat p-2 mb-2">
                {fileRequirements}
            </div>
        )}
        <div
            className={classes.join(' ')}
            onClick={handleDropzoneClick}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onMouseEnter={() => setHighlight(true)}
            onMouseLeave={() => setHighlight(false)}
            ref={dropzoneRef}
        >
            <div className="stack-start-center-3">
                <FileInput
                    accept={accept}
                    disabled={disabled}
                    hidden={true}
                    multiple={multi}
                    name="name"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    type="file"
                />
                <Icon className={disabled ? css.disabledIcon : css.icon} name={icon} />
                <div className="stack-col-center-3 stack-stretch">
                    <div>{label || defaultLabel}</div>
                    {errorMessage && (<div className={css['error-message']}>{errorMessage}</div>)}
                    {!multi && value && (
                        <div className="stack-3">
                            {downloadUrl && (<Button onClick={(event) => handleFileDownload(event, value._id)}>Download</Button>)}
                            {!disabled && (<Button color="danger" onClick={(event) => handleFileDelete(event, value)}>Delete</Button>)}
                        </div>
                    )}
                    {error && (<div className={css['error-message']}>{error}</div>)}
                </div>
            </div>
            {preview && !isEmpty(valueAsArray) && (<>
                <hr />
                {multi && (
                    <Grid className={css.preview} width="200px" height="200px">
                        {valueAsArray.map((file) => (
                            <div className={css.file} key={file._id || `file-${Math.random().toString(36).slice(2)}`}>
                                <div className={`stack-col-center-center-3 ${css.overlay}`}>
                                    {downloadUrl && (<Button onClick={(event) => handleFileDownload(event, file._id)}>Download</Button>)}
                                    <Button color="danger" onClick={(event) => handleFileDelete(event, file)}>Delete</Button>
                                </div>
                                {<Thumbnail image={file} />}
                            </div>
                        ))}
                    </Grid>
                )}
                {!multi && (<img className={css.image} src={value.url} alt="" />)}
            </>)}
        </div>
    </>);
};

const FDropzone = AsFormik(Dropzone, {
    extractValue: (files) => files,
});

export {
    Dropzone,
    FDropzone,
};

import { useState } from 'react';
import { capitalize, get } from 'lodash';
import { Options, SeriesLineOptions } from 'highcharts';
import { simpleDate } from 'smg-common';
import { MultiSelect, ReactSelect, Highcharts } from 'Components';

import type { Option } from 'smg-common';
import { MetaAttributionStatistic, MetaCampaignInsights, MetaInsightsMetric } from 'Types/MetaCampaignInsights';
import { TtdCampaignInsights, TtdInsightsMetric } from 'Types/TtdCampaignInsights';
import {
    AttributionWindowOption,
    ConversionLevelOption,
    InsightMetricOption,
    MetaInsightMetricOption,
    TtdInsightMetricOption,
} from './InFlight.types';

type InsightsNodes = MetaCampaignInsights['nodes'] | TtdCampaignInsights['nodes']

type AttributionStatisticKeys = keyof Omit<MetaAttributionStatistic, 'startDate' | 'endDate'>;
type InsightsMetric = MetaInsightsMetric & TtdInsightsMetric;

interface DailyInsightsChartBaseProps {
    adsetOptions: Option[],
    adOptions: Option[],
}

interface MetaDailyInsightsChartProps extends DailyInsightsChartBaseProps {
    type: 'meta'
    metricOptions: InsightMetricOption[],
    selectedAttributionWindow: AttributionWindowOption,
    selectedConversionLevel: ConversionLevelOption,
    data: MetaCampaignInsights['nodes'],
    isSelfServe?: boolean,
}

interface TtdDailyInsightsChartProps extends DailyInsightsChartBaseProps {
    type: 'ttd'
    metricOptions: TtdInsightMetricOption[],
    data: TtdCampaignInsights['nodes'],
}

type DailyInsightsChartProps = MetaDailyInsightsChartProps | TtdDailyInsightsChartProps

type BuildChartOptionsParams = {
    type: 'meta',
    nodes: MetaCampaignInsights['nodes'],
    insightMetric: MetaInsightMetricOption,
    selectedNodes: string[],
    attributionWindow: AttributionWindowOption,
    conversionLevel: ConversionLevelOption,
} | {
    type: 'ttd',
    nodes: TtdCampaignInsights['nodes'],
    insightMetric: TtdInsightMetricOption,
    selectedNodes: string[],
}

type BuildSeriesFn = (
    nodeType:
        | 'dailyConversions.brand'
        | 'dailyConversions.sku'
        | 'dailySalesValues.brand'
        | 'dailySalesValues.sku'
        | 'dailyInsightsMetrics',
    property: AttributionStatisticKeys | keyof InsightsMetric,
    seriesNodes: InsightsNodes,
) => SeriesLineOptions[];

const buildChartOptions = (params: BuildChartOptionsParams): Options => {
    const buildCategories = (node: (InsightsMetric | MetaAttributionStatistic)[]) => (
        node
            .filter((metric)  => metric.startDate !== undefined) // Campaigns will always have a start date, but just in case
            .map(metric => new Date(metric.startDate as string))
            .sort((a, b) => a.valueOf() - b.valueOf())
            .map(date => formatInsightDate(date))
    )

    const buildSeries: BuildSeriesFn = (nodeType, property, seriesNodes) =>
        seriesNodes.map((node) => ({
            type: 'line',
            name: node.level === 'campaign' ? 'Campaign total' : `${capitalize(node.level)} - ${node.name}`,
            data:
                // @ts-ignore Trying to convince TS that a discriminated union of different nodeTypes is acceptable
                //            would result in a lot of useless code, so just ignoring this error
                get(node, nodeType)?.map((metric: MetaAttributionStatistic | InsightsMetric) => [
                    metric.startDate ? formatInsightDate(metric.startDate) : null,
                    nodeType === 'dailyInsightsMetrics'
                        ? (metric as InsightsMetric)[property as keyof InsightsMetric]
                        : (metric as MetaAttributionStatistic)[property as AttributionStatisticKeys],
                ]) || [],
        }));

    const formatInsightDate = (insightDate: string | Date) => simpleDate.format(new Date(insightDate), 'DD/MM/YYYY')

    let categories;
    let series: SeriesLineOptions[];

    switch (params.type) {
    case 'meta':
        const  selectedMetaNodes = params.nodes.filter(node => params.selectedNodes.find(selectedNode => selectedNode === node.metaId))
        switch (params.insightMetric.value) {
        case 'conversions':
            const conversionsProperty = params.conversionLevel.value === 'sku' ? 'dailyConversions.sku' : 'dailyConversions.brand';
            categories = buildCategories(params.nodes[0].dailyConversions[params.conversionLevel.value] || [])
            series = buildSeries(conversionsProperty, params.attributionWindow.value, selectedMetaNodes)
            break;
        case 'salesValues':
            const salesValuesProperty = params.conversionLevel.value === 'sku' ? 'dailySalesValues.sku' : 'dailySalesValues.brand';
            categories = buildCategories(params.nodes[0].dailySalesValues[params.conversionLevel.value] || [])
            series = buildSeries(salesValuesProperty, params.attributionWindow.value, selectedMetaNodes);
            break;
        default:
            categories = buildCategories(params.nodes[0].dailyInsightsMetrics)
            series = buildSeries('dailyInsightsMetrics', params.insightMetric.value, selectedMetaNodes)
            break;
        }
        break;
    case 'ttd':
        const selectedTtdNodes = params.nodes.filter(node => params.selectedNodes.find(selectedNode => {
            // Creative nodes are composite keyed with adgroup id
            if (selectedNode.indexOf('-') > -1) {
                const [creativeId, adgroupId] = selectedNode.split('-')
                return creativeId === node.ttdId && adgroupId === node.parentId
            } else {
                return selectedNode === node.ttdId
            }
        }))
        categories = buildCategories(params.nodes[0].dailyInsightsMetrics)
        series = buildSeries('dailyInsightsMetrics', params.insightMetric.value, selectedTtdNodes)
        break;
    }

    return {
        title: { text: `Daily ${params.insightMetric.label}` },
        yAxis: {
            title: {
                text: params.insightMetric.label
            }
        },
        xAxis: {
            categories,
        },
        tooltip: {
            formatter: function () {
                const rows: string[] = [
                    `<strong>${this.series.name}</strong>`,
                    `${this.x}`,
                    `${params.insightMetric.label}: <strong>${params.insightMetric.formatter(this.y)}</strong>`,
                ];

                return rows.join('<br />');
            },
        },
        series,
    }
}

const DailyInsightsChart = (props: DailyInsightsChartProps) => {
    const { adsetOptions, adOptions, metricOptions, type } = props;

    const [selectedInsightMetric, setSelectedInsightMetric] = useState<InsightMetricOption | TtdInsightMetricOption>(metricOptions[0]);
    const [selectedAdSetNodes, setSelectedAdSetNodes] = useState<string[]>(type === 'meta' ? [props.data[0].metaId] : [props.data[0].ttdId]);
    const [selectedAdNodes, setSelectedAdNodes] = useState<string[]>([]);

    let chartOptions;
    let refinedMetricOptions;

    switch (type) {
    case 'meta':
        chartOptions = buildChartOptions({
            type: type,
            nodes: props.data,
            insightMetric: selectedInsightMetric as MetaInsightMetricOption,
            selectedNodes: [...selectedAdSetNodes, ...selectedAdNodes],
            attributionWindow: props.selectedAttributionWindow,
            conversionLevel: props.selectedConversionLevel,
        })

        refinedMetricOptions = props.isSelfServe
            ? metricOptions
            : metricOptions.filter(option => option.value !== 'cumulativeRoas');
        break;
    case 'ttd':
        chartOptions = buildChartOptions({
            type: type,
            nodes: props.data,
            insightMetric: selectedInsightMetric as TtdInsightMetricOption,
            selectedNodes: [...selectedAdSetNodes, ...selectedAdNodes],
        })

        refinedMetricOptions = metricOptions;
        break;
    default:
        break;
    }

    return (
        <div className="box-raised p-3 mb-3">
            <div className="mb-3 stack-end-center-3">
                <MultiSelect
                    label={`${type === 'ttd' ? 'Ad-groups' : 'Ad-sets'}`}
                    options={adsetOptions}
                    value={selectedAdSetNodes}
                    onChange={setSelectedAdSetNodes}
                    placeholder="Select..."
                    style={{ width: '15em' }}
                />
                <MultiSelect
                    label={`${type === 'ttd' ? 'Creatives' : 'Ads'}`}
                    options={adOptions}
                    value={selectedAdNodes}
                    onChange={setSelectedAdNodes}
                    placeholder="Select..."
                    style={{ width: '15em' }}
                />
                <ReactSelect
                    label="Metric"
                    options={refinedMetricOptions}
                    value={selectedInsightMetric.value}
                    onChange={setSelectedInsightMetric}
                    style={{ width: '15em' }}
                />
            </div>
            <Highcharts
                options={chartOptions}
            />
        </div>
    )
}

export default DailyInsightsChart;
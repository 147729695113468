import { formatters } from '../index.js';

// TODO: Add full self serve activity type to common
interface ActivityWithTotalInsightsMetrics {
    totalInsightsMetrics: {
        impressions: number;
        reach: number;
        engagements: number;
        frequency: number;
        clicks: number;
        engagementRate: number;
        spend: number;
        netMediaSpend: number;
        cpm: number;
        totalSales: number;
        salesValue: number;
        roas: number;
        roi: number;
        aov: number;
    };
}

const calculateAov = (salesValue: number | undefined, totalSales: number | undefined) => (salesValue && totalSales) ? salesValue / totalSales : 0;
const calculateCpm = (spend: number | undefined, impressions: number | undefined) => (spend && impressions) ? (spend / impressions) * 1000 : 0;
const calculateEngagementRate = (clicks: number | undefined, impressions: number | undefined) => (clicks && impressions) ? (clicks / impressions) * 100 : 0;
const calculateRoas = (salesValue: number | undefined, netMediaSpend: number | undefined) => (salesValue && netMediaSpend) ? salesValue / netMediaSpend : 0;
const calculateRoi = (salesValue: number | undefined, spend: number | undefined) => (salesValue && spend) ? salesValue / spend : 0;

const metricRenderer = {
    'impressions': (value: number) => formatters.asNumber(value),
    'reach': (value: number) => formatters.asNumber(value),
    'frequency': (value: number) => formatters.asNumber(value, 2),
    'engagementRate': (value: number) => formatters.asPercentage(value),
    'spend': (value: number) => formatters.asCurrency(value),
    'cpm': (value: number) => formatters.asCurrency(value, 2),
    'totalSales': (value: number) => value.toString(),
    'salesValue': (value: number) => formatters.asCurrency(value, 2),
    'roas': (value: number) => formatters.asCurrency(value, 2),
    'roi': (value: number) => formatters.asCurrency(value, 2),
    'aov': (value: number) => formatters.asCurrency(value, 2),
    'engagements': (value: number) => formatters.asNumber(value),
} as const;

function getTotalActivitiesMetrics(activities: ActivityWithTotalInsightsMetrics[]) {
    return activities.reduce((prev, curr) => {
        const { totalInsightsMetrics } = curr;

        return {
            impressions: prev.impressions + totalInsightsMetrics.impressions,
            engagements: prev.engagements + totalInsightsMetrics.engagements,
            clicks: prev.clicks + totalInsightsMetrics.clicks,
            engagementRate: calculateEngagementRate(prev.clicks + totalInsightsMetrics.clicks, prev.impressions + totalInsightsMetrics.impressions),
            spend: prev.spend + totalInsightsMetrics.spend,
            netMediaSpend: prev.netMediaSpend + totalInsightsMetrics.netMediaSpend,
            cpm: calculateCpm(prev.spend + totalInsightsMetrics.spend, prev.impressions + totalInsightsMetrics.impressions),
            totalSales: prev.totalSales + totalInsightsMetrics.totalSales,
            salesValue: prev.salesValue + totalInsightsMetrics.salesValue,
            roas: calculateRoas(prev.salesValue + totalInsightsMetrics.salesValue, prev.netMediaSpend + totalInsightsMetrics.netMediaSpend),
            roi: calculateRoi(prev.salesValue + totalInsightsMetrics.salesValue, prev.spend + totalInsightsMetrics.spend),
            aov: calculateAov(prev.salesValue + totalInsightsMetrics.salesValue, prev.totalSales + totalInsightsMetrics.totalSales),
        };
    }, {
        impressions: 0,
        engagements: 0,
        engagementRate: 0,
        clicks: 0,
        spend: 0,
        netMediaSpend: 0,
        cpm: 0,
        totalSales: 0,
        salesValue: 0,
        roas: 0,
        roi: 0,
        aov: 0,
    });
}

export default{
    calculateAov,
    calculateCpm,
    calculateEngagementRate,
    calculateRoas,
    calculateRoi,
    getTotalActivitiesMetrics,
    metricRenderer,
}

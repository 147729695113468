import {
    createContext,
    createRef,
    forwardRef,
    useContext,
    useEffect,
    useState
} from 'react';
import { isFunction } from 'lodash';

import { Icon } from '../';

import css from './Panel.module.scss';

const PanelContext = createContext();

function updateHeight(panelContentDiv, panelState = {}) {
    if (!panelContentDiv) return null;

    const {
        isCollapsed,
        setIsCollapsing,
        setIsExpanding,
        setHeight,
    } = panelState;

    const currentHeight = panelContentDiv.scrollHeight;
    const newHeight = panelContentDiv.querySelector('.content')?.scrollHeight;

    if (newHeight !== currentHeight) {
        setIsCollapsing(isCollapsed);
        setIsExpanding(!isCollapsed);
        setHeight(isCollapsed ? 0 : newHeight);

        setTimeout(() => {
            setIsCollapsing(false);
            setIsExpanding(false);
        }, 250); // Wait for any inner transitions to complete - 250ms in the css
    }
}

const PanelProvider = (props) => {
    const {
        children,
        contentRef,
        isCollapsed,
        isCollapsing,
        isExpanding,
        setIsCollapsing,
        setIsExpanding,
        setHeight
    } = props;

    return (
        <PanelContext.Provider
            value={{
                updateHeight: () => {
                    updateHeight(
                        contentRef.current,
                        {
                            isCollapsed,
                            isCollapsing,
                            isExpanding,
                            setIsCollapsing,
                            setIsExpanding,
                            setHeight,
                        },
                    );
                }
            }}
        >
            {children}
        </PanelContext.Provider>
    );
};

const usePanel = () => {
    const context = useContext(PanelContext) || {};
    return context;
};

const Panel = forwardRef((props, ref) => {
    const {
        animations = true,
        boxRaised = false,
        chevron = true,
        children,
        className = '',
        collapsed = false,
        color,
        flat,
        trigger,
        rightSlot,
        leftSlot,
        headerClasses = '',
        tight,
    } = props;

    const [height, setHeight] = useState(0);
    const [isAnimated] = useState(animations);
    const [isCollapsed, setIsCollapsed] = useState(collapsed);
    const [isCollapsing, setIsCollapsing] = useState(false);
    const [isExpanding, setIsExpanding] = useState(false);
    const contentRef = createRef();

    // Listen for 'collapsed' prop updates
    useEffect(() => {
        if (collapsed !== isCollapsed) {
            setIsCollapsed(collapsed);
        }
    }, [collapsed]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const contentHeight = contentRef.current?.clientHeight;
        const shouldExpand = !isCollapsed && contentHeight === 0;
        const shouldCollapse = isCollapsed && contentHeight > 0;

        if (updateHeight && (shouldExpand || shouldCollapse)) {
            updateHeight(contentRef.current, { isCollapsed, setIsCollapsing, setIsExpanding, setHeight });
        }
    }, [collapsed, isCollapsed]); // eslint-disable-line react-hooks/exhaustive-deps

    const classes = [
        css.panel,
        ...(color && css[color] ? [css[color]] : []),
        ...(flat ? [css.flat] : []),
        ...(boxRaised? [css.boxRaised]: []),
        className,
    ];

    const style = {};
    if (color && !css[color]) {
        style.backgroundColor = color;
    }

    const contentClasses = [
        css.content,
        ...(isCollapsed ? [css['is-collapsed']] : []),
        ...(isCollapsing ? [css['is-collapsing']] : []),
        ...(isExpanding ? [css['is-expanding']] : []),
    ];

    const stopPropagation = (event) => event.stopPropagation();
    const hide = () => setIsCollapsed(true);
    const show = () => setIsCollapsed(false);
    const toggle = () => setIsCollapsed(!isCollapsed);

    const context = { hide, isCollapsed, isCollapsing, isExpanding, show, toggle };

    const compiledHeaderClasses = [css.header, 'stack-even-center', tight ? 'p-2' : 'p-3'];
    if (headerClasses) compiledHeaderClasses.push(headerClasses);

    return (<PanelProvider
        contentRef={contentRef}
        height={height}
        isCollapsed={isCollapsed}
        isCollapsing={isCollapsing}
        isExpanding={isExpanding}
        setIsCollapsing={setIsCollapsing}
        setIsExpanding={setIsExpanding}
        setHeight={setHeight}
    >
        <div
            className={classes.join(' ')}
            ref={ref}
            style={style}
        >
            <div className={compiledHeaderClasses.join(' ').trim()} onClick={() => toggle()}>
                {chevron && (<Icon
                    className={`mr-2 ${css['icon-chevron']}`}
                    name="chevron_right"
                    style={{
                        transform: `rotate(${isCollapsed ? '0' : '90'}deg)`
                    }}
                />)}
                {leftSlot && (<div className={`${css.nowrap} stack-start-center-2 ${!trigger ? 'stack-stretch' : null}`} onClick={stopPropagation}>{leftSlot}</div>)}
                {trigger && (<div className="stack-start-center stack-stretch">
                    {trigger}
                </div>)}
                {rightSlot && (<div className={`${css.nowrap} stack-end-center-2`} onClick={stopPropagation}>{isFunction(rightSlot) ? rightSlot(context) : rightSlot}</div>)}
            </div>
            <div
                className={contentClasses.join(' ')}
                ref={contentRef}
                style={{ maxHeight: height }}
            >
                {isAnimated && !isCollapsed && (<div className={`${css.content} p-3 pb-5`}>{isFunction(children) ? children(context) : children}</div>)}
                {!isAnimated && !isCollapsed && (<div className="p-3">{isFunction(children) ? children(context) : children}</div>)}
            </div>
        </div>
    </PanelProvider>);
});
Panel.displayName = 'Panel';

export default Panel;
export { usePanel };

import { useState } from 'react';

import { Icon, IconButton, Modal } from 'Components';

import css from './PreviewFile.module.scss';

const PreviewFile = (props) => {
    const defaultTrigger = (<Icon className={`like-link ${css['preview-icon']}`} name="preview" />);
    const { files = [], trigger = defaultTrigger, className } = props;
    const showTrigger = (
        <span className={className} onClick={() => { setShowPreview(true); setFile(props.file); }}>
            {trigger}
        </span>
    );

    const [file, setFile] = useState();
    const [showPreview, setShowPreview] = useState();

    if (!file || !showPreview) return showTrigger;

    const fileIndex = files.findIndex(f => f._id === file._id);
    const nextFile = files[fileIndex + 1];
    const previousFile = files[fileIndex - 1];

    let content;
    const meta = (
        <div className="stack-end-center">
            {files.length > 1 && <div>
                <IconButton
                    className="mr-1"
                    name="chevron_left"
                    onClick={() => setFile(previousFile)}
                    disabled={!previousFile}
                />
                <IconButton
                    className="mr-4"
                    name="chevron_right"
                    onClick={() => setFile(nextFile)}
                    disabled={!nextFile}
                />
            </div>}
            <Icon className="like-link no-color" name="clear" onClick={() => setShowPreview()} />
        </div>
    );

    const imgFormats = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/tiff', 'image/svg', 'image/bmp'];
    if (imgFormats.includes(file.mimetype)) {
        content = (<>
            <div className="mb-3">{meta}</div>
            <div className={css['image-container']}>
                <div className={css['image-preview']} style={{backgroundImage: `url("${file.url}")`}} />
            </div>
        </>);
    } else if (file.mimetype === 'application/pdf') {
        content = (<>
            <div className="mb-3">{meta}</div>
            <div>
                <iframe title="Preview file" className={css['pdf-preview']} src={file.url} />
            </div>
        </>);
    } else {
        content = (<>
            <div className="mb-3">{meta}</div>
            <div>No preview available</div>
        </>);
    }

    return (<>
        {showTrigger}
        <Modal onCancel={() => setShowPreview()} show={showPreview} width="max">
            <Modal.Body>{content}</Modal.Body>
        </Modal>
    </>);
}

export default PreviewFile;

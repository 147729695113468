import moment from 'moment';
import {
    formatters,
    getTotalCostOfSelectedActivitiesAndItems,
} from '../../index.js';

const morrisonsCitrusAdTemplate = {
    name: 'Morrisons - CitrusAd',
    pandaDocId: 'D7P2Bxs4gGvKcKnB6poTwb',
    companies: ['5b3dddac93c1d436671c07ac'],
    recipientRoles: [
        { label: 'Client\'s email', value: 'Client' },
    ],

    getDocumentName: (campaign, plan, activities, items, customFields) => {
        const now = moment().format('YYMMDD-HHmm');
        return formatters.asFilename(`[${now}] ${plan.customPlanId || plan.name}`);
    },

    getTokens: (campaign, plan, activities, items) => {
        const totalCost = getTotalCostOfSelectedActivitiesAndItems(plan, activities, items);

        return [
            { name: 'plan.customPlanId', value: plan.customPlanId || plan.name },
            { name: 'plan.owner.email', value: plan.owner.user.email },
            { name: 'company.name', value: campaign.company.name },
            { name: 'brand.name', value: campaign.brands[0].name },
            { name: 'campaign.name', value: campaign.name },
            { name: 'campaign.totalCost', value: formatters.asCurrency(totalCost, { decimals: 2 }) },
            { name: 'category.name', value: plan.rCategory?.name || '-' },
            { name: 'subcategory.name', value: plan.rSubCategory?.name || '-' },
        ];
    },
};

export default morrisonsCitrusAdTemplate;

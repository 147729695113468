const pinterest = {
    labelOptions: [
        { label: 'Custom', value: 'custom' },
        { label: 'Signup', value: 'signup' },
        { label: 'Lead', value: 'lead' },
        { label: 'Checkout', value: 'checkout' },
    ],
    sourceTypeOptions: [
        { label: 'Online', value: 'online' },
        { label: 'Offline', value: 'offline' },
    ],
    salesValueOptions: [
        { label: 'Total order value', value: 'totalOrderValue' },
        { label: 'Total conversions', value: 'totalConversions' },
    ],
};

export default pinterest;
import PropTypes from 'prop-types';

import { HelpModal } from '..';
import css from './FormGroup.module.scss';

/**
 * Wraps any child elements in a form-group DIV
 */
const FormGroup = (props) => {
    const {
        children,
        className='',
        errorMsg,
        helpMsg,
        helpModal, // either { title, body } or undefined (show a help icon that opens a help modal)
        hidden,
        label,
        required,
        successMsg,
        topRightSlot,
        ...restProps
    } = props;

    // NOTE: we ditinguish between empty string (an empty label tag is rendered) and null/undefined (label tag is not rendered at all)
    const withoutLabel = label === undefined || label === null;

    const labelElement = withoutLabel
        ? undefined
        : (
            <div className={css.labelContainer}>
                <label className={css.label}>
                    { label }
                    { required && <span className={css.required}>*</span> }
                    { helpModal && <HelpModal children={helpModal.body} iconClassName={css.helpModalIcon} title={helpModal.title} /> }
                </label>
                <span className={css.topRight}>
                    {topRightSlot}
                </span>
            </div>
        );

    const classes = [
        className,
        css['form-group'],
        labelElement ? '' : css['no-margin'], // When there is no label, we remove the bottom margin too
        hidden ? css.hidden : ''
    ].join(' ');

    return (
        <div className={classes} {...restProps}>
            {labelElement}
            {children}
            {errorMsg ? <div className={css.errorMsg} data-smg-error-message>{errorMsg}</div> : null}
            {helpMsg ? <div className={css.helpMsg}>{helpMsg}</div> : null}
            {successMsg ? <div className={css.successMsg}>{successMsg}</div> : null}
        </div>
    );
};

FormGroup.propTypes = {
    errorMsg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    helpMsg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    successMsg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    required: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
};

export default FormGroup;

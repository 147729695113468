import { HTMLProps, useState } from 'react';
import { escapeRegex, formatters } from 'smg-common';

import { ErrorMessage, Grid, Http, Loading, Pill, RetailerPill, useFetch } from 'Components';
import type { AudienceFilters, AudienceSegment, AudienceSizeTypes } from 'Types';
import AudienceBrowserFilters from './AudienceBrowserFilters';

import css from './AudienceBrowser.module.scss';

interface AudienceBrowserProps extends Omit<HTMLProps<HTMLDivElement>, 'sizes'> {
    sizes: AudienceSizeTypes[];
    onFiltersChanged: (filter: AudienceFilters) => void;
    filterValues?: AudienceFilters,
    retailerId?: string;
    companyId?: string;
}

const getVisibleAudienceSegments = (allAudienceSegments: AudienceSegment[], filters: AudienceFilters, retailerId?: string, companyId?: string) => {
    return allAudienceSegments.filter(audienceSegment => {
        const nameRegExp = new RegExp(escapeRegex(filters.name), 'i');
        const matchNameFilter = !filters.name || nameRegExp.test(audienceSegment.label) || nameRegExp.test(audienceSegment.name);

        // The optional retailerId on the AudienceBrowser props should always override the retailer filter dropdown
        const retailerToMatch = retailerId || filters.retailer;
        const matchRetailerFilter = !retailerToMatch || audienceSegment.retailer._id === retailerToMatch;

        const matchCompanyFilter =
            !companyId || // When there is no filter on company (eg audiences app)
            audienceSegment.company === undefined || // The audience segment is visible to all companies
            audienceSegment.company?._id === companyId; // The audience belongs to a company and there is a filter on that specific company

        const matchTypeFilter = !filters.type || audienceSegment.type === filters.type;
        const matchRSubCategoryFilter = !filters.rSubCategory || audienceSegment.rSubCategories?.some(c => c._id === filters.rSubCategory);

        const objectiveRegExp = new RegExp(escapeRegex(filters.objective), 'i');
        const matchObjectiveFilter = !filters.objective || (audienceSegment.objective && objectiveRegExp.test(audienceSegment.objective));

        return matchNameFilter && matchRetailerFilter && matchCompanyFilter && matchTypeFilter && matchObjectiveFilter && matchRSubCategoryFilter;
    });
};

const AudienceBrowser = ({ sizes, retailerId, companyId, onFiltersChanged, filterValues, ...props }: AudienceBrowserProps) => {
    const [selectedFilters, setSelectedFilters] = useState<AudienceFilters>(filterValues ?? {})
    const [audienceSegments, isLoading, errorMessage] = useFetch<AudienceSegment[]>(() => Http.get('/audiences/audience-segments'), [])

    // When a retailerId prop is passed, hide retailer components because all audienceSegments will belong to one retailer
    const showRetailerComponents = !retailerId;
    const showSegmentSize = sizes.includes('segmentSize');
    const showSocialEstimate = sizes.includes('socialEstimate');
    const showDisplayEstimate = sizes.includes('displayEstimate');

    if (isLoading) return <Loading />
    if (errorMessage) return <ErrorMessage message={errorMessage} />
    if (!audienceSegments) return <ErrorMessage message="There are no audiences available." />

    let visibleAudienceSegments = getVisibleAudienceSegments(audienceSegments, selectedFilters, retailerId, companyId);

    const handleFiltersChanged = (filter: AudienceFilters) => {
        onFiltersChanged(filter);
        setSelectedFilters(prevFilters => ({
            ...prevFilters,
            ...filter,
        }))
        visibleAudienceSegments = getVisibleAudienceSegments(audienceSegments, selectedFilters);
    };

    const renderSelectedAudience = (showRetailerComponents: boolean, selectedAudienceId?: string) => {
        const selectedAudience = visibleAudienceSegments?.find(audience => audience._id === selectedAudienceId);

        if (!selectedAudience) return (
            <div className="text-center p-5">
                <span className="text-italic">Select an audience to view details.</span>
            </div>
        );

        return (<div className="m-3">
            <h2 className="stack-even mb-2">
                {selectedAudience.label || selectedAudience.name}
                {showRetailerComponents && <RetailerPill retailer={selectedAudience.retailer} />}
            </h2>
            <Grid className="pt-2 pb-2" maxColumns={3} minWidth="125px">
                {showSegmentSize && <article className="stack-col-center-center">
                    <small>Segment size</small>
                    <span className={css['metric']}>{formatters.humanise(selectedAudience.segmentSize)}</span>
                </article>}
                {showSocialEstimate && <article className="stack-col-center-center">
                    <small>Social estimate</small>
                    <span className={css['metric']}>{formatters.humanise(selectedAudience.socialEstimate)}</span>
                </article>}
                {showDisplayEstimate && <article className="stack-col-center-center">
                    <small>Display estimate</small>
                    <span className={css['metric']}>{formatters.humanise(selectedAudience.displayEstimate)}</span>
                </article>}
            </Grid>
            <div className="stack align-items-center mt-4 mb-4">
                <span className="text-bold mr-2">Type:</span>
                <Pill className="mr-5" textColor="white" colour={`audience-type-${selectedAudience.type}`}>{selectedAudience.type}</Pill>
                <span className="text-bold mr-2">Objective:</span>
                <span>{selectedAudience.objective ?? 'None'}</span>
            </div>
            <div>
                <span className="text-bold">Description</span>
                <p className="mt-2">{selectedAudience.description}</p>
            </div>
        </div>)
    };

    return (<div {...props}>
        {audienceSegments?.length > 0 && (<>
            <AudienceBrowserFilters audienceSegments={audienceSegments} onFiltersChanged={handleFiltersChanged} selectedFilters={selectedFilters} showRetailerComponents={showRetailerComponents} />
            <div className="d-flex">
                {!visibleAudienceSegments?.length && (<ErrorMessage className="m-3" message="There are no audiences that match this criteria."/>)}
                {visibleAudienceSegments?.length > 0 && (<>
                    <div className={`col-md table-container box-raised m-2 ${css.audienceTable}`}>
                        <table className="table">
                            <thead>
                                <tr>
                                    {showRetailerComponents && <th className="text-center">Retailer</th>}
                                    <th>Name</th>
                                    <th className="text-center">Type</th>
                                    {showSegmentSize && <th className="text-right">Segment size</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {visibleAudienceSegments.map((audienceSegment) => (
                                    <tr key={audienceSegment._id} className={selectedFilters.selectedAudience?._id === audienceSegment._id ? css['selected'] : null}>
                                        {showRetailerComponents && <td>
                                            <div className="stack-center">
                                                <RetailerPill retailer={audienceSegment.retailer} />
                                            </div>
                                        </td>}
                                        <td>
                                            <span
                                                className="like-link"
                                                onClick={() => handleFiltersChanged({ selectedAudience: audienceSegment })}
                                            >
                                                {audienceSegment.label || audienceSegment.name}
                                            </span>
                                        </td>
                                        <td className="text-center"><Pill textColor="white" colour={`audience-type-${audienceSegment.type}`}>{audienceSegment.type}</Pill></td>
                                        {showSegmentSize && <td className="text-right">{formatters.humanise(audienceSegment.segmentSize)}</td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md box-raised m-2">
                        {renderSelectedAudience(showRetailerComponents, selectedFilters.selectedAudience?._id)}
                    </div>
                </>)}
            </div>
        </>)}
    </div>)
}

export default AudienceBrowser;

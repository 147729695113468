import Carousel from './Carousel';
import useModal from '../hooks/useModal';

import css from './ImageCarousel.module.scss';

const ImageCarousel = (props) => {
    const { images } = props;

    const { show: showImages } = useModal({
        className: css['photo-preview'],
        clickOutsideToClose: true,
        header: 'Images',
        template: (props) => {
            const { images, index } = props;

            return (<Carousel
                adaptiveHeight
                images={images.map((image) => (
                    <div className="stack-col-center-center-4 p-2" key={image._id}>
                        <img className={css.image} src={image.url} alt="" />
                        <div>{image.caption}</div>
                    </div>
                ))}
                initialSlide={index}
            />);
        },
    });

    return (
        <Carousel
            images={images.map((image, index) => (
                <div
                    key={image._id}
                    className={[css.image, 'stack-col-center-center-2 mx-2'].join(' ')}
                    onClick={() => showImages({ images, index })}
                    style={{ backgroundImage: `url(${image.url})` }}
                >
                    {image.caption && (<div className={css.caption}>{image.caption}</div>)}
                </div>
            ))}
            slidesToShow={images.length > 4 ? 4 : images.length}
            slidesToScroll={4}
            swipe={false}
        />
    );
}

export default ImageCarousel;

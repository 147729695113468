import Carousel from 'Components/Carousel/Carousel';
import { formatters } from 'smg-common';

import css from './CampaignImageCarousel.module.scss';

interface CampaignImageCarouselProps {
    images:{
        _id: string;
        url: string;
        touchpointName: string;
        planName: string;
        startDate: string;
    }[];
    index: string;
}

export const CampaignImageCarousel = (props: CampaignImageCarouselProps) => {
    const  { images, index } = props;
    return (
        <Carousel
            adaptiveHeight
            images={images.map((image) => (
                <div className="stack-col-center-center-4 p-3" key={image._id}>
                    <img className={css.image} src={image.url} alt="" />
                    <p className="m-0 text-bold">{image.touchpointName}</p>
                    <p className="m-0">{image.planName}</p>
                    <p className="m-0">{formatters.asDate(image.startDate)}</p>
                </div>
            ))}
            initialSlide={index}
        />
    );
};

export default CampaignImageCarousel;
import _ from 'lodash-es';
import {
    calculateActivityDiscountsValue,
    formatters,
    getTotalCostOfActivities,
    getTotalCostOfSelectedActivitiesAndItems,
    getItemCost,
    simpleDate,
} from '../../index.js';

const tvgBuyerConfirmationBaseTemplate = {
    name: 'TVG Campaign Confirmation Form - Base',
    pandaDocId: 'rYq5RpNBjjnX93BpWDq34P',
    getDocumentName: (campaign, plan, activities, items, customFields) => {
        return formatters.asFilename(`Campaign Confirmation Form - ${customFields.phasingMonth} - ${campaign.brands[0].name} - ${campaign.name}`);
    },
    companies: ['6220dd9a55d3ce88a5474a8d'],
    recipientRoles: [
        { label: 'Client\'s email', value: 'Client' },
    ],
    customFields: [
        { label: 'Phasing month', value: 'phasingMonth' },
        { label: 'Funding split', value: 'fundingSplit', isOptional: true },
    ],
    getTokens: (campaign, plan, activities, items) => {
        // Prepare reference data
        const sortedActivities = _.sortBy(activities, 'startDate');
        const earliestActivity = _.first(sortedActivities);
        const latestActivity = _.last(sortedActivities);
        const tvgSupplierCode = _.get(_.find(campaign.company.supplierCodes, { label: 'tvg-supplier-code' }), 'value');

        if (!tvgSupplierCode) {
            throw new Error(`Missing supplier code \'TVG Supplier Code\' for company ${campaign.company.name}`);
        }

        const tokens = [
            {
                name: 'customPlanId',
                value: plan.customPlanId || plan.name,
            },
            {
                name: 'company',
                value: campaign.company.name,
            },
            {
                name: 'brand',
                value: campaign.brands[0].name,
            },
            {
                name: 'category',
                value: plan.rCategory?.name,
            },
            {
                name: 'totalCost',
                value: formatters.asCurrency(getTotalCostOfSelectedActivitiesAndItems(plan, activities, items), { decimals: 2 }),
            },
            {
                name: 'liveMonth',
                // Month of start date of earliest activity
                value: simpleDate.getMonth(earliestActivity.startDate, true),
            },
            {
                name: 'fundingMonth',
                // Month of the end date of the last activity
                value: simpleDate.getMonth(latestActivity.endDate, true),
            },
            {
                name: 'invoiceMonth',
                // Month of start date of last activity
                value: simpleDate.getMonth(latestActivity.startDate, true),
            },
            {
                name: 'tvgSupplierCode',
                value: tvgSupplierCode,
            },
        ];

        return tokens;
    },
    getPricingTables: (plan, activities, items) => {
        // Prepare reference data
        const totalCostOfActivities = getTotalCostOfActivities(plan.activities);

        const rows = [];

        activities.forEach((activity) => {
            const activityTotalDiscountAmount = calculateActivityDiscountsValue(activity);

            const row = {
                // Options is a mandatory field
                options: {},
                data:{
                    // QTY is a mandatory field
                    QTY: 1,
                    Name: activity.touchpoint.name,
                    Label: activity.label,
                    Description: activity.description,
                    Price: activity.cost || 0,
                    StartDate: formatters.asDate(activity.startDate),
                    EndDate: formatters.asDate(activity.endDate),
                    ActualCost: formatters.asCurrency(activity.actualCost),
                    // 'Discount' is a reserved word for PandaDoc pricing tables - so we need to use our own term
                    DiscountValue: formatters.asCurrency(activityTotalDiscountAmount),
                },
            };

            rows.push(row);
        });

        items.forEach((item) => {
            const row = {
                // Options is a mandatory field
                options: {},
                data: {
                    // QTY is a mandatory field
                    QTY: 1,
                    Name: item.title,
                    Label: '',
                    Description: '',
                    Price: getItemCost(item, totalCostOfActivities) || 0,
                    StartDate: formatters.asDate(item.startDate),
                    EndDate: formatters.asDate(item.endDate),
                },
            };

            rows.push(row);
        });

        return [{
            name: 'Media Breakdown',
            data_merge: true,
            sections: [
                {
                    // Title is a mandatory field
                    title: 'Activities and Items',
                    default: true,
                    rows,
                },
            ],
        },];
    },
};

export default tvgBuyerConfirmationBaseTemplate;
